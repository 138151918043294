<template>
  <v-dialog
    :key="group?.id"
    v-model="visible"
    max-width="290"
  >
    <v-card>
      <v-form
        v-if="group"
      >
        <v-card-title>{{ group?.id ? 'Edit' : 'Create' }} Allergen Group</v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field
            v-model="group.name"
            label="Name"
            dense
            class="mb-4"
          />
          <v-select
            v-model="group.drug_classes"
            :items="drug_classes"
            label="Drug Classes"
            item-value="id"
            item-text="name"
            multiple
            return-object
            dense
          />
          <v-switch
            v-model=" group.relevant"
            label="Relevant"
            dense
          />
          <v-switch
            v-model=" group.displayed"
            label="Displayed"
            dense
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="saveAllergenGroup(group)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  metaInfo: {
    title: 'Allergen Groups',
  },
  data () {
    return {
      visible: false,
      loading: false,
      editDialog: {
        visible: false,
      },
      allergen_groups: [],
      drug_classes: [],
      groups: [],
    }
  },
  watch: {
    group: {
      deep: true,
      handler () {
        this.visible = this.group !== null
      },
    },
  },
  created () {
    this.axios.get('admin/drugs/classes').then((response) => {
      this.drug_classes = response.data
    })
  },
  methods: {
    close () {
      this.group = null
      this.$emit('close')
    },
    saveAllergenGroup (group) {
      this.loading = true
      const promise = group.id
        ? this.axios.patch('admin/allergens/groups/' + group.id, group)
        : this.axios.post('admin/allergens/groups', group)

      promise.then(() => {
        this.$emit('updated', group)
        this.close()
      }).catch(error => {
        if (error.response?.data?.message) {
          this.$toast.error(error.response.data.message)
        }
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
